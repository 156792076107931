/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import { isMobile } from './libs/userAgent';
import MobileHome from './pages/mobileHome';
import PrivacyPolicy from './pages/privacyPolicy';


function App() {






  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={isMobile() ? <MobileHome /> : <Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Navigate to="/" replace={true} />} />

        </Routes>

        <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
      </Router>
    </>
  );
}

export default App;
