import logo from '../../assets/logo.png'
import { useEffect, useState } from 'react';
import './index.css'

const Header = (props: any) => {

  // const [mobileOpen, setMobileOpen] = useState(false)
  const [winScrollTop, setScrollTop] = useState(0)

  const [ActiveNav, setActiveNav] = useState('Home')
  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll);
  }, [])

  const handleScroll = () => {
    setScrollTop(window.scrollY)

    const navItems = document.querySelectorAll('.nav-item');

    for (let i = navItems.length - 1; i >= 0; i--) {
      const navItem = navItems[i];
      // @ts-ignore
      const section = document.getElementById(navItem.dataset.to).getBoundingClientRect();
      if (section.top <= 10) {
        // @ts-ignore
        setActiveNav(navItem.getAttribute('data-to'));
        break;
      }
    }
  }



  const goToPage = (to: any) => {
    const dom = document.getElementById(to);
    if (dom) {
      dom.scrollIntoView({ behavior: "smooth", });
    }
  }
  return (
    <>
      <div className={['header', winScrollTop > 10 ? 'headerScroll' : ''].join(' ')}>
        <div className="logo">
          <img src={logo} alt="justintel" />
        </div>
        <div className="nav">

        </div>
        <div className="nav">
          <ul className="main">

            <li>
              <div
                className={['MenuMobileLink', 'nav-item', 'button-main', ActiveNav === 'Home' ? 'active' : ''].join(' ')}
                data-to={'Home'}
                onClick={() => {
                  goToPage('Home')
                  // setMobileOpen(!mobileOpen)
                }}
              >
                Home
              </div>
            </li>
            <li>
              <div
                className={['MenuMobileLink', 'nav-item', 'button-main', ActiveNav === 'Airdrop' ? 'active' : ''].join(' ')}
                data-to={'Airdrop'}
                onClick={() => {
                  goToPage('Airdrop')
                  // setMobileOpen(!mobileOpen)
                }}
              >
                Airdrop
              </div>
            </li>
            <li>

              <div
                className={['MenuMobileLink', 'nav-item', 'button-main', ActiveNav === 'Product' ? 'active' : ''].join(' ')}
                data-to={'Product'}
                onClick={() => {
                  goToPage('Product')
                  // setMobileOpen(!mobileOpen)
                }}
              >
                Product
              </div>
            </li>
            <li>

              <div
                className={['MenuMobileLink', 'nav-item', 'button-main', ActiveNav === 'Tokenomics' ? 'active' : ''].join(' ')}
                data-to={'Tokenomics'}
                onClick={() => {
                  goToPage('Tokenomics')
                  // setMobileOpen(!mobileOpen)
                }}
              >
                Tokenomics
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Header;