import React, { useEffect, useState } from 'react';
import MobileHeader from '../../components/mobileHeader';
import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, { Autoplay, EffectCreative } from "swiper";
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
import './index.css'
import discord from '../../assets/discord.png'
import LINK from '../../assets/link.png'
import twitter from '../../assets/twitter.png'
import Mirror from '../../assets/mirror.png'
import { useAccount, useConnect, useContractRead, useContractWrite, useNetwork, usePrepareContractWrite, useSwitchNetwork } from "wagmi";

import { Pagination } from "swiper";
import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';
import treeData from '../../libs/tree.json';
import { ethers, BigNumber } from 'ethers';
import { toast } from 'react-toastify';
// const root = treeData.root;
const leaves = treeData.leaves;

const MobileHome = (): JSX.Element => {

  // const [showConnectWallet, setShowConnectWallet] = useState(false)

  const { connect, connectors, error, pendingConnector } =
    useConnect()
  const [claimedCount, setClaimecCount] = useState(0)

  const { isConnected, address } = useAccount()
  const { chain } = useNetwork()
  const { switchNetwork } =
    useSwitchNetwork()
  const [mintLoading, setMintLoading] = useState<boolean>(false)
  const [isWhitelist, setIsWhitelist] = useState<boolean>(true)
  const [isClaimed, setIsClaimed] = useState<boolean>(false)



  // const [proof, setProof] = useState<string | null>(null);
  // const [userAddress, setUserAddress] = useState<string>('');

  // (2) 计算 Merkle 树
  //@ts-ignore
  const merkleTree = new MerkleTree(leaves.map(ethers.utils.hexlify), keccak256, { sortPairs: true });

  // (3) 获取证明
  const getProofByAddressAndAmount = (address: any, amount: any) => {
    const leaf = ethers.utils.solidityKeccak256(['address', 'uint256'], [address, amount]);
    return merkleTree.getHexProof(leaf);
  };

  // const handleGenerateProof = (address: any) => {
  //   const amountPerAddress = ethers.utils.parseEther('10000');
  //   const proof = getProofByAddressAndAmount(address, amountPerAddress);
  //   return proof;
  // };
  const handleGenerateProof = (address: any): any => {
    const amountPerAddress = ethers.utils.parseEther('50000');
    if (address) {
      const proof = getProofByAddressAndAmount(address, amountPerAddress);
      if (proof) {
        return proof as readonly `0x${string}`[];
      }
    }
    return null;
  };

  // isClaim查询
  const { refetch: isClaimedFun } = useContractRead(
    {
      // @ts-ignore
      address: process.env.REACT_APP_CONTRACT_ADDRESS,
      abi: [
        {
          inputs: [
            {
              "internalType": "address",
              "name": "_claimer",
              "type": "address"
            }
          ],
          name: "isClaimed",
          outputs: [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          stateMutability: "view",
          type: "function"
        },
      ],
      functionName: 'isClaimed',
      args: [address || '0x0000000'],
      // chainId: 421613,
      chainId: 42161,
      enabled: Boolean(address),
      onSuccess(data: any) {
        setIsClaimed(data)
        // @ts-ignore
        // setCurrentEntryLens(parseInt(Number(data.entriesLength._hex), 10))
      },
      onError(error: any) {
        console.log('Error1212122211', error)
      },
    })

  // claim数量查询
  const { refetch: ClaimedCounrFun } = useContractRead(
    {
      // @ts-ignore
      address: process.env.REACT_APP_CONTRACT_ADDRESS,
      abi: [
        {

          inputs: [

          ],
          name: "getClaimedAddressCount",
          outputs: [
            {
              "internalType": "uint256",
              "name": "",
              "type": "uint256"
            }
          ],
          stateMutability: "view",
          type: "function"
        },
      ],
      functionName: 'getClaimedAddressCount',
      // args: [address || '0x0000000'],
      // chainId: 421613,
      chainId: 42161,
      // enabled: Boolean(address),
      onSuccess(data: any) {

        setClaimecCount(parseInt(data._hex, 16))
        // setIsClaimed(data)
        // @ts-ignore
        // setCurrentEntryLens(parseInt(Number(data.entriesLength._hex), 10))
      },
      onError(error: any) {
        console.log('Error1212122211', error)
      },
    })

  const {
    config,
    // error: prepareError,
    // isError: isPrepareError,
    // isSuccess: perSuccess
  } = usePrepareContractWrite({
    // @ts-ignore
    address: process.env.REACT_APP_CONTRACT_ADDRESS,
    abi: [
      {
        inputs: [
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256"
          },
          {
            internalType: "bytes32[]",
            name: "merkleProof",
            type: "bytes32[]"
          }
        ],
        name: "claim",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function"
      }
    ],
    functionName: 'claim',
    args: [ethers.utils.parseEther('50000'), handleGenerateProof(address)],
    overrides: {
      from: address,
      // value: ethers.utils.parseEther(JSON.stringify(0)),
      gasLimit: BigNumber.from('3100000')
    },
    // chainId: 421613,
    chainId: 42161,
    // cacheTime: 2_000,
    enabled: Boolean(address),
    // staleTime: 2_000,
    onSuccess(data: any) {
      console.log('Success', data)
    },
    onError(error: any) {

      console.log('Error1212122211', error.message)
    },
  })
  const {
    // data: mintNft, 
    // error, isError,
    write: enterWrite,
    isLoading
  } = useContractWrite({
    ...config,
    onSuccess(data: any) {
      setMintLoading(false)

      toast.success('Claim Success! Waiting for block confirmation.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
    },
    onError(error: any) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      })
      setMintLoading(false)
    },
  })


  useEffect(() => {
    ClaimedCounrFun()
    if (address) {
      isClaimedFun()
    }
  })


  const mint = async () => {

    setMintLoading(true)
    // testwork Net
    // if (chain?.id !== 421613) {
    //   await switchNetwork?.(421613)
    // }

    if (chain?.id !== 42161) {
      await switchNetwork?.(42161)
    }
    // @ts-ignore
    if (handleGenerateProof(address).length <= 0) {
      setIsWhitelist(false)
      setMintLoading(false)
      return
    }

    await enterWrite?.()

    // setMintLoading(false)
  }



  return (
    <>
      <div className="mpage">

        <MobileHeader></MobileHeader>
        <div className="main" id='Home'>
          <div className="banner">
            <h1 className="title" data-aos="fade-up">
              <img src={require('../../assets/m-slogan.png').default} alt="" width={'100%'} />
            </h1>
            <div className="about" data-aos="fade-up">
              <h3>About Us</h3>
              <p>JustIntel (JIT) is a decentralized, trust-based social platform which allows users to connect effectively with projects and each other, while constructing comprehensive digital identities. The social connections are facilitated by on-chain data mining and big data algorithms. All the transaction data on JIT, including ratings, will be analyzed to generate valuable and actionable Web3 “Intels” which will be stored on-chain for permissionless accessibility.</p>
            </div>
          </div>
          <div className="airdrop" id="Airdrop">
            <div className="contain">
              <div className="pic" data-aos="fade-up">
                <img src={require('../../assets/airgrop-pic.png').default} alt="" />
              </div>
              <div className="text" data-aos="fade-up">
                <h2><span>$</span>JIT Airdrop</h2>
                <p>Check your eligibility to  claim</p>
                <div className="progress-box">
                  <div className="progress">
                    <div className="count"
                      style={{
                        width: `${claimedCount / 1000 * 100}%`
                      }}
                    ></div>
                  </div>
                  <div className="num">
                    {claimedCount} / 1000
                  </div>
                </div>
                {/* <button className="claim" >
                  claim
                </button> */}
                {
                  isConnected
                    ? <button
                      className="claim"
                      onClick={() => {
                        if (!isClaimed) {
                          mint()
                        }
                      }}
                    >
                      {

                        isClaimed ?
                          <span>Already claimed</span>
                          :

                          mintLoading
                            ? <div className={['absolute z-10 flex justify-center align-middle w-full h-full left-0 top-0 transition-all', mintLoading ? 'opacity-1' : 'opacity-0'].join(' ')}>
                              <img className="inline-block spinner-border animate-spin-slowing" src={require('../../assets/spinner-white.svg').default} alt="" width="30" height="30" />
                            </div>
                            :
                            <span>{isWhitelist ? 'claim' : <>You are not <br /> eligible to claim</>}</span>
                      }

                    </button>
                    : <>
                      {connectors.map((connector) => (
                        <button
                          disabled={!connector.ready}
                          key={connector.id}
                          className="claim"
                          onClick={() => connect({ connector })}
                        >
                          {/* {connector.name} */}
                          claim
                          {!connector.ready && ' (unsupported)'}
                          {isLoading &&
                            connector.id === pendingConnector?.id &&
                            ' (connecting)'}
                        </button>
                      ))}

                      {error && <div>{error.message}</div>}
                    </>
                }

              </div>

            </div>
          </div>
          <div className="coupon">
            <div className="hotAir" data-aos="fade-right">
              <img src={require('../../assets/hotAir.png').default} alt="" />
            </div>
            <h1 className="blockTile" data-aos="fade-up">JIT Coupon Utilities</h1>
            <div className="list">
              <div className="item" data-aos="fade-rigth">
                <div className="content">
                  <div className="num">
                    1.
                  </div>
                  <div className="text">
                    Priority access to special outfits in subsequent "Digital Soul" products.
                  </div>
                </div>
              </div>
              <div className="item" data-aos="fade-left">
                <div className="content">
                  <div className="num">
                    2.
                  </div>
                  <div className="text">
                    Free airdrops of parts of the "Digital Soul" outfits.
                  </div>
                </div>
              </div>
              <div className="item" data-aos="fade-right">
                <div className="content">
                  <div className="num">
                    3.
                  </div>
                  <div className="text">
                    Entitlement to anti-dilution airdrops each time the token inflates.
                  </div>
                </div>
              </div>
              <div className="item" data-aos="fade-left">
                <div className="content">
                  <div className="num">
                    4.
                  </div>
                  <div className="text">
                    When new features, services, or products are launched, coupon holders can have priority usage rights.
                  </div>
                </div>
              </div>
            </div>
            <div className="block">
              <div className="mission" data-aos="fade-up-rigth">
                <div className="title">
                  <div className="bg">
                  </div>
                  <div className="title-text">
                    Justintel
                  </div>

                </div>
                <div className="content">
                  <div className="subtitle">
                    <span>mission</span>
                  </div>
                  <p>At JustIntel, our vision is to become the beacon of trust in the trustless world of Decentralization, where individuals and communities can confidently engage and collaborate with complete peace of mind.
                  </p>
                </div>
                <div className="star">
                  <img src={require('../../assets/star.png').default} alt="" width={'100%'} />
                </div>
              </div>
              <div className="vision" data-aos="fade-up-left">
                <div className="title">
                  <div className="bg">
                  </div>
                  <div className="title-text">
                    Justintel
                  </div>

                </div>
                <div className="content">
                  <div className="subtitle">
                    <span>Vision</span>
                  </div>
                  <p>Our mission is to empower individuals and communities by providing the tools, knowledge, and decentralized platform necessary to navigate and thrive in the trustless landscape of Web3.
                  </p>
                </div>
                <div className="star">
                  <img src={require('../../assets/star.png').default} alt="" width={'100%'} />
                </div>
              </div>

              <div className="bgstar" >
                <img data-aos="fade-in" src={require('../../assets/star.png').default} alt="" width={'100%'} />
              </div>
            </div>
          </div>
          <div className="JustIntel" id="Product">
            <h1 className="blockTile" data-aos="fade-up">JustIntel</h1>
            <p className="subtext" data-aos="fade-left">
              Trust-based Social Platform with Actionable INTELS
            </p>
            <div className="productlist">
              <div className="top">
                <div className="left">
                  <div className="img" data-aos="fade-up">
                    <img src={require('../../assets/product1.png').default} alt="" width={'100%'} />
                  </div>
                  <div className="text">
                    <h4 className="title" data-aos="fade-up">REAL UTILITIES</h4>

                    <h5 className="subtitle" data-aos="fade-up">Credibility Score: </h5>
                    <p data-aos="fade-up">Calculated using machine learning algorithms based on user info and ratings.
                    </p>
                    <h5 className="subtitle" data-aos="fade-up">Social:  </h5>
                    <p data-aos="fade-up">Enable messaging, comments, and interaction among friends.
                    </p>

                  </div>
                </div>
                <div className="right">
                  <div className="img" data-aos="fade-up">
                    <img src={require('../../assets/product1.png').default} alt="" width={'100%'} />
                  </div>
                  <div className="text">
                    <h4 className="title" data-aos="fade-up">REAL UTILITIES</h4>

                    <h5 className="subtitle" data-aos="fade-up">Credibility Score: </h5>
                    <p data-aos="fade-up">Calculated using machine learning algorithms based on user info and ratings.
                    </p>
                    <h5 className="subtitle" data-aos="fade-up">Social:  </h5>
                    <p data-aos="fade-up">Enable messaging, comments, and interaction among friends.
                    </p>

                  </div>
                </div>
              </div>
              <div className="center">
                <Swiper
                  className="list"
                  slidesPerView={1.8}
                  spaceBetween={20}
                  centeredSlides={true}
                  allowTouchMove={true}
                  slideToClickedSlide={true}
                  modules={[Pagination]}
                  pagination
                  speed={500}
                >
                  <SwiperSlide >
                    <div className="item ">
                      <img src={require('../../assets/product3.png').default} alt="" width={'100%'} />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide >
                    <div className="item">
                      <img src={require('../../assets/product4.png').default} alt="" width={'100%'} />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide >
                    <div className="item">
                      <img src={require('../../assets/product5.png').default} alt="" width={'100%'} />
                    </div>
                  </SwiperSlide>
                </Swiper>
                <div >



                </div>
              </div>
              <div className="bottom">
                <div className="text">
                  <h4 className="title" data-aos="fade-up">Robust Social Interactions</h4>
                  <p data-aos="fade-up">JustIntel offers seamless access to information across channels, enabling users to express their views anytime, anywhere. Users can sync their actions with social platforms and actively participate in rating and commenting on others' credibility, fostering transparency and trust through meaningful interactions.</p>
                </div>
              </div>
            </div>
            <div className="digital">
              <div className="img" data-aos="fade-up">
                <img src={require('../../assets/digital.png').default} alt="" width={'100%'} />
              </div>
              <div className="text" data-aos="fade-up">
                Reinforcing Synergies among
                info, rating and digital soul
              </div>
            </div>
          </div>
          <div className="kol">
            <div className="quan"></div>
            <div className="img" >
              <img src={require('../../assets/m-KOL.png').default} data-aos="fade-up" alt="" width={'100%'} />
            </div>
          </div>
          <div className="tokenomics" id="Tokenomics">
            <div className="jit" data-aos="fade-up">
              <h4 className="title">JIT Tokenomics</h4>
              <p>TBD.Coming Soon!</p>
            </div>
            <div className="token-content">
              <h2 data-aos="fade-up">JIT Coupononmics</h2>
              <p data-aos="fade-up">JIT Coupon is not the official governance token of the project, which will be released after the launch of our official product</p>
            </div>
            <div className="chat">
              <div className="left" data-aos="fade-right">
                <img src={require('../../assets/chat.png').default} alt="" width={'100%'} />
              </div>
              <div className="right" >
                <div className="text">
                  <p data-aos="fade-up">
                    JIT Coupon is a novel element within our project, bringing a range of unique utilities and possibilities to our community. These coupons will have various applications on our platform, including but not limited to obtaining specific services, participating in specific activities, and claiming exclusive privileges.
                  </p>
                  <br />
                  <p data-aos="fade-up">
                    JIT Couponomics comprises an initial issuance of 20% of the total supply, with subsequent monthly airdrops and an extra 6% anti-dilution coupon for JIT coupon holders. The final distribution allocate coupons for rug victims (5%), community members (30%), ecosystem development (15%), team and early contributors (20%), treasury (20%), and advisors and partners (10%).
                  </p>
                  <br />
                  <p data-aos="fade-up">
                    We believe that JIT Coupon will become an integral part of our community. They will enhance our community cohesion, stimulate our engagement, and bring more value to our members.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="roadmap">
            <div className="blockTile" data-aos="fade-up">Road Map</div>
            <div className="list">
              <div className="item item1" data-aos="fade-right">
                <h4>PRE-LAUNCH: BUILD COMMUNITY</h4>
                <p>We aim to create a vibrant and engaged community by fostering connections, providing valuable resources, and nurturing meaningful interactions, laying the foundation for shared success in our project.</p>
              </div>
              <div className="item item2" data-aos="fade-left">
                <h4>V1 PRODUCT RELEASE</h4>
                <p>Launch essential features including messaging, posting, and rating, enabling users to connect and engage. Additionally, personalized digital identities and a credibility system will be introduced, fostering transparency and individuality within our platform.</p>
              </div>
              <div className="item item3" data-aos="fade-right">
                <h4>V2 PRODUCT UPGRADE</h4>
                <p>Improve key functions by implementing a robust rating system, NFTizing personal accessories, integrating digital identities, expanding to other ecosystems, and creating sub-communities based on user interests. These enhancements will elevate user experience and foster a dynamic and engaging platform.</p>
              </div>
              <div className="item item4" data-aos="fade-left">
                <h4>V3 PRODUCT Commericialize</h4>
                <p>We will generate actionable insights applicable to social, credit evaluation, career, and investment domains. Additionally, we will collaborate with partners to develop tailored products utilizing our insights for mutual benefit.</p>
              </div>
            </div>
          </div>
          <div className="ecosystem">
            <div className="left">
              <div className="blockTile" data-aos="fade-up">Ecosystem</div>
              <p data-aos="fade-up">JustIntel’s products aim to produce utility for and build trust among users and institutions</p>
            </div>
            <div className="right" data-aos="fade-up">
              <img src={require('../../assets/ecosystem.png').default} alt="" width={'100%'} />
            </div>
          </div>
        </div>
        <div className="m-footer" data-aos="fade-up">
          <div className="logo">
            <img src={require('../../assets/logo-footer.png').default} alt="" />
            <p>Building Trust in the Trustless World</p>
          </div>
          <div className="social_group">
            <a href="https://twitter.com/JustIntel_io" rel="noreferrer" target={'_blank'} className="item">
              <img src={twitter} alt="twitter" />
            </a>
            <a href="https://discord.gg/hqmFMr6M" rel="noreferrer" target={'_blank'} className="item">
              <img src={discord} alt="discord" />
            </a>
            <a href="https://linktr.ee/justintel" rel="noreferrer" target={'_blank'} className="item">
              <img src={LINK} alt="Link" />
            </a>
            <a href="https://mirror.xyz/justintel.eth" rel="noreferrer" target={'_blank'} className="item">
              <img src={Mirror} alt="Mirror" />
            </a>

          </div>
          <div className="list">
            <a href="/privacy-policy" target={'_blank'} className="footer_link">
              <span>Privacy policy</span>
            </a>
            <a href="/terms" className="footer_link">
              <span>Terms of service</span>
            </a>

          </div>
          <div className=" copy_right" id='copyright'>© 2023 JUSTINTEL. All rights reserved.</div>
        </div>
        <div className="bottom-quan">
          <div className="content"></div>
        </div>
        <div className="mpagebg">
          <img src={require('../../assets/m-top-bg.png').default} alt="" width={'100%'} />
          <img src={require('../../assets/m-center-bg.png').default} alt="" width={'100%'} />
          <img src={require('../../assets/m-bottom-bg.png').default} alt="" width={'100%'} />
        </div>
      </div>
    </>
  );
}

export default MobileHome;