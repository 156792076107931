import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";


const NavItem = (Props: any) => {

  const goToPage = () => {
    const dom = document.getElementById(Props.to);
    if (dom) {
      dom.scrollIntoView({ behavior: "smooth", });
    }
    Props.callback && Props.callback(Props.to);
  }

  return (
    <li className={['navlist-item', Props.active === Props.to ? 'active' : ''].join(' ')} onClick={goToPage}>
      {Props.children}
    </li>
  );
}

const MobileHeader = (props: any) => {
  const [navBtn, setNavBtnClassNames] = useState('iconUl');
  const [navModel, setNavModelClassNames] = useState('nav-model');
  const [winScrollTop, setScrollTop] = useState(0)

  const [ActiveNav, setActiveNav] = useState('Home')

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll);
  }, [])

  useEffect(() => {
    console.log(winScrollTop)
  }, [winScrollTop])

  const handleScroll = () => {
    setScrollTop(window.scrollY)

    const navItems = document.querySelectorAll('.nav-item');

    for (let i = navItems.length - 1; i >= 0; i--) {
      const navItem = navItems[i];
      // @ts-ignore
      const section = document.getElementById(navItem.dataset.to).getBoundingClientRect();
      if (section.top <= 10) {
        // @ts-ignore
        setActiveNav(navItem.getAttribute('data-to'));
        break;
      }
    }
  }

  const changeNavStatus = () => {
    const isActive = navBtn.indexOf('active');
    if (isActive < 0) {
      setNavBtnClassNames('iconUl active');
      setNavModelClassNames('nav-model active');
    } else {
      setNavBtnClassNames('iconUl');
      setNavModelClassNames('nav-model');
    }
  }

  const liClick = (to: any) => {
    setNavBtnClassNames('iconUl');
    setNavModelClassNames('nav-model');
    setActiveNav(to)
  }

  return (
    <>
      <div className={['mobile-header', winScrollTop > 10 ? 'mheaderScroll' : ''].join(' ')}>
        <div className="mobile-header-container">

          <div className="mobile-header-container-logo">
            <Link to={'/'}>
              <img src={require('../../assets/logo.png').default} alt="" height="100%" />
            </Link>
          </div>
          <div className="mobile-header-container-navBtn icon">
            <ul className={navBtn} onClick={changeNavStatus}>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>

      <div className={navModel}>
        <div className="bg" onClick={liClick}></div>
        <div className="model-content">
          <div className="phoneNav">
            <ul className="navList textList">
              <NavItem active={ActiveNav} to="Home" callback={liClick}>
                {/* <span className="navlist-item-icon home" /> */}
                Home
              </NavItem>
              <NavItem active={ActiveNav} to="Airdrop" callback={liClick}>
                {/* <span className="navlist-item-icon home" /> */}
                Airdrop
              </NavItem>
              <NavItem active={ActiveNav} to="Product" callback={liClick}>
                {/* <span className="navlist-item-icon home" /> */}
                Product
              </NavItem>
              <NavItem active={ActiveNav} to="Tokenomics" callback={liClick}>
                {/* <span className="navlist-item-icon home" /> */}
                Tokenomics
              </NavItem>


            </ul>
          </div>

        </div>
      </div>
    </>
  );
}

export default MobileHeader;
